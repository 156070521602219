/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import './donate_page.css'

const values = [5, 10, 20, 30, 50, 100, 200, 500, 1000]
const payments = values.map((value, i) => ({
  id: `${value}`, label: `R$${value},00`, checked: i === 0
}))

const paymentLinks = {
  '5': 'pl_ZKp7EqzlonVPGAbfXfYzeDw21kRAvYrB',
  '10': 'pl_EjOwlG63NBqX1ZJf6oUly2WzRYvn7o41',
  '20': 'pl_NZXj5Kk9lgpW6JrIeHl6aMV2e8Rn4QdB',
  '30': 'pl_6bNJp1kzeA7BEXNtw7Fpxa2dnr8g3wLO',
  '50': 'pl_91ekwoBPzJbyjkVvhYhdR64qOxdWMmK8',
  '100': 'pl_NXJ3BpYxZ6zVjw9uPqTwyQO8naE0qdrm',
  '200': 'pl_2K93bpZNRmzPmeoiOVTqaP5BgJY6Lxrk',
  '500': 'pl_8N39g2Lbp4Ey20Mcwt7MZ7zkmJOal0od',
  '1000': 'pl_LqwQzyPDgNr6mVIPKUGG4o9dj3Bkx1V0'
}

export default function DonatePage() {
  const [paymentItems, setPaymentItems] = useState(payments)

  const changePayment = (e) => {
    setPaymentItems((prevItems) => prevItems.map((item) => {
      return item.id === e.target.id
        ? { ...item, checked: true }
        : { ...item, checked: false }
    }));
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const selectedPayment = paymentItems.find(({ checked }) => checked)

    window.open(`https://payment-link.pagar.me/${paymentLinks[selectedPayment.id]}`)
  }

  return (
    <div className='page donate-page'>
      <div className='image-cover'>
        <div className='inner-section'>
          <div className='title'>Projeto Mulheres de Força</div>
          <div>Selecione o valor que pode doar e contribua com a força de dezenas de mulheres!</div>
          <br />
          <div>Formas de pagamento da doação disponíveis: <b>PIX, Cartão de Crédito e Boleto.</b></div>
          <form className='form' onSubmit={onSubmit}>
            <div className='field payment-field'>
              <div className='select-payment'>Selecione o valor da doação</div>
              <div className='payment-container'>
                {paymentItems.map(({ id, label, checked }) => (
                  <label key={id} className={`payment ${id}`}>
                    <input
                      id={id}
                      name={id}
                      type="checkbox"
                      checked={checked || false}
                      onChange={changePayment}
                      className='field-checkbox'
                    />
                    {label}
                  </label>
                ))}
              </div>
            </div>
            <br />
            <div>Para doar outros valores, entre em contato no e-mail: projetomulheresdeforca@gmail.com</div>
            <div className='form-warn'>A doação a seguir para o projeto é feita através da plataforma Pagar.me. Essa plataforma exige que o doador insira alguns dados e o seu endereço!</div>
            <button className='continue-payment' type='submit'>Clique Aqui para Prosseguir com a Doação</button>
          </form>
        </div>
      </div>
    </div>
  )
}
