import React from 'react'

export const questions = [
  {
    question: 'Para quem é o Projeto Mulheres de Força?',
    answer: 'Ele tem caráter universal, pois é direcionado às mulheres em geral, independente de idade, orientação sexual ou religião. Dentre as mulheres acompanhadas estão mulheres, em sua maioria, negras e chefes de família e também mulheres com deficiência e mães de pessoas com deficiência.',
  },
  {
    question: 'E as crianças e adolescentes são atendidos?',
    answer: 'As crianças e adolescentes até os 18 anos, de qualquer gênero, também são atendidos no PMF porque entendemos que a criação de filhos é um dos principais empreendimentos da mulher.',
  },
  {
    question: 'Como a comunidade participa do Projeto Mulheres de Força?',
    answer: 'As ações do projeto são desenvolvidas com o intuito sempre de possibilitar a participação da comunidade em sua organização, planejamento, execução e manutenção.',
  },
  {
    question: 'Onde funciona o Projeto Mulheres de Força?',
    answer: 'O PMF funciona na Rua Jose Bonifácio dos Santos, 180, Itacaranha, em Salvador/BA, bairro situado no subúrbio da capital.',
  },
  {
    question: 'Qual é o objetivo principal do Projeto Mulheres de Força?',
    answer: 'O nosso objetivo principal é fazer com que as mulheres tenham um espaço de apoio e troca de experiências. Entendemos que com o fortalecimento da autoconsciência feminina, contribuímos para a redução dos índices de desigualdade e violência que afetam mulheres da comunidade.',
  },
  {
    question: 'Quais são os pilares principais do Projeto Mulheres de Força?',
    answer: (
      <React.Fragment>
        <li>Incentivar a elevação da autoestima da mulher;</li>
        <li>Incentivar o autocuidado e os cuidados básicos de atenção à saúde feminina;</li>
        <li>Fomentar ações de qualificação profissional para geração de emprego e renda para que as mulheres conquistem sua autonomia econômica e social.</li>
      </React.Fragment>
    ),
  },
  {
    question: 'Por que o nome Mulheres de Força?',
    answer: 'O nosso projeto foi pensado como uma forma de incentivar a força inerente à toda mulher e encorajá-la a manifestar os seus pensamentos e a ser quem ela é.',
  },
  {
    question: 'Como funciona o PMF atualmente?',
    answer: 'Em nossa sede situada no subúrbio da capital baiana, o projeto faz acompanhamento psicológico de mulheres residentes na região e adjacências. E temos trabalhado para aumentar o número de mulheres acompanhadas, inclusive por profissionais de outras áreas e também de atividades desenvolvidas. Ocorrem, ainda, mensalmente, encontros e palestras educativas de temáticas próprias ao mundo feminino.',
  },
  {
    question: 'Como se dão os atendimentos psicológicos?',
    answer: 'Os atendimentos ocorrem de forma individual em intervalos que dependem de cada caso e também por meio de atendimentos em grupo como forma de fortalecer as mulheres participantes e favorecer a troca de experiências e apoio mútuo. Além disso, favorece a capacidade de comunicação e relacionamento através do compartilhamento de diversas experiências.',
  },
  {
    question: 'Como se mantém o Projeto Mulheres de Força?',
    answer: 'O PMF depende exclusivamente de doações para a sua manutenção e ampliação, pois não há qualquer subsídio do Governo para o seu funcionamento. A união faz a força. Muitas vezes, pensamos que nossa contribuição isolada não irá fazer diferença, mas faz sim porque ela se soma a várias outras e assim conseguimos o nosso objetivo. O que seria do mar sem as gotículas de água? Contribua!',
  },
  {
    question: 'Por que o PMF precisa de doações?',
    answer: (
      <React.Fragment>
        O nosso projeto é mantido por doações e precisamos reformar e ampliar o espaço já existente para aumentar as atividades já desenvolvidas, bem como para custear as despesas de manutenção, tais como água, energia, limpeza e outros gastos.
      </React.Fragment>
    ),
  },
  {
    question: 'Posso ajudar o PMF sendo voluntário?',
    answer: 'O PMF está organizando o ingresso de voluntários em suas atividades. São várias atividades e você pode ajudar conforme a sua aptidão, como secretaria, atendimento, organização da casa para os atendimentos e após eles, marcação de consultas, professor para cursos diversos, médicos, psicólogos, fisioterapeutas, etc.',
  },
]