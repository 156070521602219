import { Link } from 'react-router-dom';
import TextSection from './text_section';
import './app.css';
import ProjectTitle from './project_title';

const pictures = Array.from(Array(7).keys()).map((i) => `foto${i + 2}.jpeg`)

const ClickHereButton = () => {
  return (
    <Link to='doe'>
      <button className='click-here'>Clique Aqui para Ajudar Dezenas de Mulheres</button>
    </Link>
  )
}

function HomePage() {
  return (
    <div className='page'>
      <div className='first-section highlight-section'>
        <div className='image-cover'>
          <div className='inner-section'>
            <ProjectTitle />
            <div className='text'>O nosso projeto foi pensado como uma forma de incentivar a força inerente à toda mulher e encorajá-la a manifestar os seus pensamentos e a ser quem ela é.</div>
            <ClickHereButton />
          </div>
        </div>
      </div>
      <div className='testimonials-section'>
        <div className='title'>Depoimentos</div>
        <div className='inner-section testimonial-videos'>
          <video className='video' src="/videos/depoimento1.mp4" controls>
          </video>
          <video className='video' src="/videos/depoimento2.mp4" controls>
          </video>
        </div>
      </div>
      <div className='testimonials-section'>
        {/* <div className='title'>Mais Depoimentos</div> */}
        <div className='inner-section'>
          <div className='testimonial-text'>
            <div className='testimonial-name'>Érica M. S. S.:</div>
            <div>
              "O projeto Mulheres de Força me ajudou em vários aspectos da minha vida, mas em especial me deu coragem pra realizar um sonho até então considerado impossível. Apoio, ajuda, incentivo... são algumas das palavras que eu posso resumir o que o projeto representa pra mim, e pra tantas mulheres resilientes e corajosas."
            </div>
          </div>
          <div className='testimonial-text'>
            <div className='testimonial-name'>Joseane C.:</div>
            <div>
              "Em um momento muito difícil da minha vida encontrei o projeto Mulheres de Força. Oi, meu nome é Joseane C., fui diagnosticada com TAG/depressão em 2021, passei muito tempo sem entender e me sentindo vazia, por não ter muita condição de pagar psicólogo, passei um bom tempo tentando tratamento, quando conheci esse projeto que me acolheu, não tinha muita esperança, mas com muit carinho e paciência, fui melhorando e vendo sentido na vida, hoje com tremenda admiração agradeço a esse projeto que salvou minha vida."
            </div>
          </div>
          <div className='testimonial-text'>
            <div className='testimonial-name'>Silvia A.:</div>
            <div>
              "O projeto Mulheres de força veio como um divisor de água em minha vida em muitos aspectos, tanto no psicológico, como no religioso e também em termos de carreira profissional, pois lá consegui dar um norte ao que eu realmente gosto e quero fazer. Agradeço imensamente ao universo por ter tido a oportunidade de ter colocado esse projeto lindo no meu caminho.
              Obrigada pelas surpresas, pelas oportunidades constantes e pelo carinho. Agradeço por cada dia, cada momento e cada inspiração.
              Obrigada a todos que dele participam por estarem sempre comigo, mesmo nos momentos mais difíceis.
              Obrigada por me ensinar, me desafiar e me ajudar a crescer e por todas as lições que aprendi, todas as experiências que tive e todas as memórias que criei até então.
              Obrigada por me dar a oportunidade de viver, amar, aprender e crescer."
            </div>
          </div>
        </div>
        <ClickHereButton />
      </div>
      <TextSection />
      <div className='photos-section'>
        <div className='title'>Fotos de Eventos</div>
        <div className='inner-section'>
          {pictures.map((picture) => (
            <img key={picture} className='picture' src={`${picture}`} alt={picture} />
          ))}
        </div>
        <ClickHereButton />
      </div>
      <div className='about-section highlight-section'>
        <div className='inner-section'>
          Realização: Ilé Asè Yié Pondà
          <br />
          <br />
          CNPJ: 36.184.314/0001-39
        </div>
      </div>
    </div>
  );
}

export default HomePage;
