import { questions } from './questions'
import './app.css'

const Question = ({ question, answer }) => {
  return (
    <div>
      <div><b>{question}</b></div>
      <br />
      <div>{answer}</div>
      <br />
      <br />
    </div>
  )
}

export default function TextSection() {
  return (
    <div className='text-section highlight-section'>
      <div className='inner-section'>
        {questions.map(({ question, answer }, index) => (
          <Question key={index} question={question} answer={answer} />
        ))}
        <button className='click-here'>Clique Aqui para Ajudar Dezenas de Mulheres</button>
      </div>
    </div>
  );
}
